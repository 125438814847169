import { Profil } from "../enum/profil.enum";
import { TicketStatus } from "../enum/ticket.enum";
import { Conf } from "../hooks/useConfFetch";
import {
  CoOwnerUnitsList,
  CustomerTicket,
  Entity,
  FicheClientApiData,
  MappedAcount,
  Mission,
  PaymentType,
} from "../interfaces/ficheClient.interface";
import { PropertyStakeholder } from "./propertyStakeholder";

export class CoOwner extends PropertyStakeholder {
  constructor(conf: Conf) {
    super(conf);
  }

  /**
   * @override
   */
  protected getTicketsCount(unit: CoOwnerUnitsList, customerTicketList: CustomerTicket[], entityId: string): number {
    return customerTicketList.filter(
      (ticket) =>
        ticket.entity_id === entityId &&
        ticket.account_id === unit.co_owner_account_id &&
        ticket.status !== TicketStatus.FINISHED
    ).length;
  }

  /**
   * @override
   */
  protected getMissionsCount(entityId: string, missionList: Mission[], unit: CoOwnerUnitsList): number {
    return missionList.filter(
      (mission) => mission.entity_id === entityId && mission.related_account_id === unit.co_owner_account_id
    ).length;
  }

  /**
   * @override
   */
  protected getAccountId(unit: CoOwnerUnitsList): string {
    return unit.co_owner_account_id;
  }

  /**
   * @override
   */
  protected getCompleteAddress(unit: CoOwnerUnitsList): string {
    return `${unit.building_address} ${unit.building_city} ${unit.building_zip_code}`;
  }

  /**
   * @override
   */
  protected buildAccounts(data: FicheClientApiData, entity: Entity): Map<string, MappedAcount[]> {
    const units: Map<string, MappedAcount[]>[] = [];
    const coOwnerUnits = this.mapUnits(data, data.co_owner_units_list, entity, Profil.CO_OWNER);
    units.push(coOwnerUnits);
    return this.flattenUnits(units);
  }

  /**
   * @override
   */
  protected addExtraAccountData(entity: Entity, unit: CoOwnerUnitsList): Partial<MappedAcount> {
    const paymentType = entity.payment_type?.map((payment: PaymentType) => payment.paymentType).join(", ");

    return {
      paymentType,
      reminderLevel: unit.recovery_reminder_level,
      lastAGDate: unit.last_general_assembly_date,
      nextAGDate: unit.next_general_assembly_date,
      properties: [
        {
          id: unit.unit_id,
          type: this.translateUnitType(unit.unit_type),
          url: this.getUnitAdbLink(unit),
        },
      ],
    };
  }
}
