import React, { useEffect, useState } from "react";

import "./App.scss";
import ErrorBoundary from "./components/error-boundary/ErrorBoudary";

import { useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import AppRoutes from "./routes/AppRoutes";
import { Conf, useConfFetch } from "./hooks/useConfFetch";

function App() {
  const navigate = useNavigate();
  const [oktaAuth, setOktaAuth] = useState({} as OktaAuth);
  const [conf, error, loading] = useConfFetch();

  useEffect(() => {
    if (!loading && !error && conf) {
      const issuer = (conf as Conf).REACT_APP_OKTA_ISSUER_URL as string;
      const clientId = (conf as Conf).REACT_APP_OKTA_CLIENT_ID as string;
      const scopes = (conf as Conf).REACT_APP_OKTA_SCOPES as string;

      setOktaAuth(
        new OktaAuth({
          issuer,
          clientId,
          redirectUri: window.location.origin + "/login/callback",
          scopes: scopes.split(",").map(scope => scope.trim()),
          pkce: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conf]);

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <ErrorBoundary>
      {Object.keys(oktaAuth).length > 0 && (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <AppRoutes />
        </Security>
      )}
    </ErrorBoundary>
  );
}

export default App;
