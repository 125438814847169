import { Buyer } from "../class/buyer";
import { CoOwner } from "../class/coOwner";
import { Lessor } from "../class/lessor";
import { Seller } from "../class/seller";
import { Tenant } from "../class/tenant";
import { Conf } from "../hooks/useConfFetch";
import { FicheClientApiData, MappedAcount, MappedFicheClient } from "../interfaces/ficheClient.interface";

const aggregatedData: Map<string, MappedFicheClient> = new Map([]);

/**
 * Fusion fiche client with the same uuid
 * @param {MappedFicheClient} fiche
 */
function aggregateToMap(fiches: MappedFicheClient[]): void {
  for (const fiche of fiches) {
    const uuid = fiche.informations.uuid;
    if (aggregatedData.has(uuid)) {
      const existingFiche = aggregatedData.get(uuid);
      if (existingFiche) {
        const existingFicheAccounts = existingFiche?.accounts as Map<string, MappedAcount[]>;
        aggregatedData.set(uuid, {
          ...existingFiche,
          accounts: new Map([...existingFicheAccounts, ...fiche.accounts]),
        });
      }
    } else {
      // Add new entry
      aggregatedData.set(uuid, {
        informations: fiche.informations,
        accounts: new Map(fiche.accounts),
      });
    }
  }
}

/**
 * Map fiche client with profil and call agregate function
 * @param {FicheClientApiData[]} data
 * @param {Conf} conf
 * @returns { MappedFicheClient[]}
 */
export function ficheClientMapping(data: FicheClientApiData[], conf: Conf): MappedFicheClient[] {
  data.forEach((ficheClientApiData: FicheClientApiData) => {
    if (ficheClientApiData.lessor_units_list.length > 0) {
      const lessor = Lessor.getInstance(conf);
      const lessorFiche = lessor.mapFicheClient(ficheClientApiData);
      aggregateToMap(lessorFiche);
    }
    if (ficheClientApiData.co_owner_units_list.length > 0) {
      const coOwner = CoOwner.getInstance(conf);
      const coOwnerFiche = coOwner.mapFicheClient(ficheClientApiData);
      aggregateToMap(coOwnerFiche);
    }
    if (ficheClientApiData.tenant_units_list.length > 0) {
      const tenant = Tenant.getInstance(conf);
      const tenantFiche = tenant.mapFicheClient(ficheClientApiData);
      aggregateToMap(tenantFiche);
    }
    if (ficheClientApiData.transaction_sellers_list.length > 0) {
      const seller = Seller.getInstance(conf);
      const sellerFiche = seller.mapFicheClient(ficheClientApiData);
      aggregateToMap(sellerFiche);
    }
    if (ficheClientApiData.transaction_buyers_list.length > 0) {
      const buyer = Buyer.getInstance(conf);
      const buyerFiche = buyer.mapFicheClient(ficheClientApiData);
      aggregateToMap(buyerFiche);
    }
  });

  return Array.from(aggregatedData.values());
}
