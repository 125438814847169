export enum TransactionStatus {
  ESTIMATE = "ESTIMATE",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  SALES_MANDATE_NOT_SIGNED = "SALES_MANDATE_NOT_SIGNED",
  SALES_MANDATE_SIGNED = "SALES_MANDATE_SIGNED",
  SALES_MANDATE_EXPIRED = "SALES_MANDATE_EXPIRED",
  AVAILABLE = "AVAILABLE",
  OFFER_SIGNED = "OFFER_SIGNED",
  SALE_AGREEMENT_NOT_SIGNED = "SALE_AGREEMENT_NOT_SIGNED",
  SALE_AGREEMENT_SIGNED = "SALE_AGREEMENT_SIGNED",
  DEED_OF_SALE_SIGNED = "DEED_OF_SALE_SIGNED",
  COMPLETED = "COMPLETED",
  BATCHED = "BATCHED",
  CANCELED = "CANCELED",
}

export const transactionStatusLabel = {
  [TransactionStatus.ESTIMATE]: "Estimation",
  [TransactionStatus.OFFER_ACCEPTED]: "Offre acceptée",
  [TransactionStatus.SALES_MANDATE_NOT_SIGNED]: "Mandat non signé",
  [TransactionStatus.SALES_MANDATE_SIGNED]: "Mandat signé",
  [TransactionStatus.SALES_MANDATE_EXPIRED]: "Mandat échu",
  [TransactionStatus.AVAILABLE]: "Disponible",
  [TransactionStatus.OFFER_SIGNED]: "Sous offre",
  [TransactionStatus.SALE_AGREEMENT_NOT_SIGNED]: "Compromis non signé",
  [TransactionStatus.SALE_AGREEMENT_SIGNED]: "Compromis signé",
  [TransactionStatus.DEED_OF_SALE_SIGNED]: "Acte authentique signé",
  [TransactionStatus.COMPLETED]: "Encaissée",
  [TransactionStatus.BATCHED]: "Vente terminée",
  [TransactionStatus.CANCELED]: "Abandonné",
};
