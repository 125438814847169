import { Profil, profileTypeUrl } from "../enum/profil.enum";

export function getFtUrl(ftUrl: string, transactionId: string): string {
  return `${ftUrl}/transactions/${transactionId?.replace("ML-TRANSACTION-", "")}/estimate/contact`;
}

export function getAccountLink(url: string, entityId: string, profil: Profil): string {
  return `${url}/customer/${entityId?.replace("ML-CUSTOMER-", "")}/${profileTypeUrl[profil]}`;
}

export function getFicheLink(url: string, entityId: string): string {
  return `${url}/customer/${entityId?.replace("ML-CUSTOMER-", "")}/dashboard`;
}
