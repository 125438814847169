import { Profil } from "../enum/profil.enum";
import { TransactionStatus, transactionStatusLabel } from "../enum/transaction-status.enum";
import { unitTypeLabel } from "../enum/unit.enum";
import { Conf } from "../hooks/useConfFetch";
import {
  CoOwnerUnitsList,
  CustomerTicket,
  Entity,
  FicheClientApiData,
  MappedAcount,
  Mission,
  SellerUnitsList,
  TransactionMappedAccount,
} from "../interfaces/ficheClient.interface";
import { getFtUrl } from "../utils/urlMappingUtils";
import { PropertyStakeholder } from "./propertyStakeholder";

export class Seller extends PropertyStakeholder {
  constructor(conf: Conf) {
    super(conf);
  }

  /**
   * @override
   */
  protected getTicketsCount(unit: SellerUnitsList, customerTicketList: CustomerTicket[], entityId: string): number {
    return 0;
  }

  /**
   * @override
   */
  protected getMissionsCount(entityId: string, missionList: Mission[], unit: CoOwnerUnitsList): number {
    return 0;
  }

  /**
   * @override
   */
  protected getAccountId(unit: SellerUnitsList): string {
    return unit.technical_primary_key;
  }

  /**
   * @override
   */
  protected getCompleteAddress(unit: SellerUnitsList): string {
    return `${unit.transaction_address} ${unit.city}`;
  }

  /**
   * @override
   */
  protected buildAccounts(data: FicheClientApiData, entity: Entity): Map<string, MappedAcount[]> {
    const units: Map<string, MappedAcount[]>[] = [];
    const sellerUnits = this.mapUnits(data, data.transaction_sellers_list, entity, Profil.SELLER);
    units.push(sellerUnits);
    return this.flattenUnits(units);
  }

  /**
   * @override
   */
  protected addExtraAccountData(entity: Entity, unit: SellerUnitsList): Partial<TransactionMappedAccount> {
    return {
      statut: transactionStatusLabel[unit.status as TransactionStatus],
      associateName: unit.lead_associate_name,
      unitCategory: unitTypeLabel[unit.unit_category],
      agency: unit.branch_name,
      transactionUrl: getFtUrl(this.urls.ft as string, unit.transaction_id),
    };
  }

  /**
   * @override
   */
  protected getFicheAdbLink(): string | undefined {
    return undefined;
  }

  /**
   * @override
   */
  protected getFicheBoLink(): string | undefined {
    return undefined;
  }

  /**
   * @override
   */
  protected getAccountAdbLink(): string | undefined {
    return undefined;
  }

  /**
   * @override
   */
  protected getAccountBoLink(): string | undefined {
    return undefined;
  }
}
