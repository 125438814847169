import { useEffect, useState } from "react";
import useQuery from "../hooks/useQuery";
import { FicheClientApiData } from "../interfaces/ficheClient.interface";
import { Conf, useConfFetch } from "./useConfFetch";
import { Error, HttpError } from "../enum/error.enum";
import { useOktaAuth } from "@okta/okta-react";
import { getPhoneParameter } from "../utils/urlParametersUtils";
import { ficheClientMapping } from "../utils/FicheClientMapping";

const DEFAULT = {
  data: [],
  isLoading: false,
  isError: false,
  error: {},
};

const useGetFicheClient = () => {
  const [conf, err, loading] = useConfFetch();
  const [datascienceApiUrl, setDatascienceApiUrl] = useState("");
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!loading && !err && conf) {
      const datascienceApiUrl = (conf as Conf).REACT_APP_DATASCIENCE_API_URL as string;
      setDatascienceApiUrl(datascienceApiUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conf]);

  const bearerTokenStorage = localStorage.getItem("okta-token-storage");
  if (!bearerTokenStorage) {
    console.error(Error.AUTH_TOKEN_NOT_FOUND);
    return {
      ...DEFAULT,
      isError: true,
      error: {
        message: Error.AUTH_TOKEN_NOT_FOUND,
      },
    };
  }
  const bearerToken = JSON.parse(bearerTokenStorage)?.accessToken.accessToken;
  if (!bearerToken) {
    console.error(Error.AUTH_TOKEN_NOT_FOUND);
    return {
      ...DEFAULT,
      isError: true,
      error: {
        message: Error.AUTH_TOKEN_NOT_FOUND,
      },
    };
  }

  const phoneNumber = getPhoneParameter("param");
  if (!phoneNumber) {
    console.error(Error.PHONE_NUMBER_NOT_FOUND);
    return {
      ...DEFAULT,
      isError: true,
      error: {
        message: Error.PHONE_NUMBER_NOT_FOUND,
      },
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isLoading, isError, error } = useQuery<FicheClientApiData>(
    `${datascienceApiUrl}/customer_summary/phone_number/${phoneNumber}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );

  const handleSignOut = async () => {
    if (error?.message === HttpError.UNAUTHORIZE || error?.message === HttpError.FORBIDDEN) {
      try {
        await oktaAuth.revokeAccessToken();
        await oktaAuth.signOut();
      } catch (signOutError) {
        console.log(signOutError);
      }
    }
  };
  handleSignOut();

  return {
    data: ficheClientMapping(data, conf as Conf),
    isLoading,
    isError,
    error,
  };
};

export default useGetFicheClient;
