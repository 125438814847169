export enum Profil {
  CO_OWNER = "CO_OWNER", // COPRO
  LESSOR = "LESSOR", // BAILLEUR
  TENANT = "TENANT", // LOC
  SELLER = "SELLER",
  BUYER = "BUYER",
}

export const profilLabel = {
  [Profil.CO_OWNER]: "Co-propriétaire",
  [Profil.LESSOR]: "Bailleur",
  [Profil.TENANT]: "Locataire",
  [Profil.SELLER]: "Vendeur",
  [Profil.BUYER]: "Acheteur",
};

export const profileTypeUrl = {
  [Profil.CO_OWNER]: "co-owner",
  [Profil.LESSOR]: "lessor",
  [Profil.TENANT]: "tenant",
  [Profil.SELLER]: "seller",
  [Profil.BUYER]: "buyer",
};
