import { useEffect, useState } from "react";

export interface Conf {
  REACT_APP_OKTA_ISSUER_URL?: string;
  REACT_APP_OKTA_CLIENT_ID?: string;
  REACT_APP_OKTA_SCOPES?: string;
  REACT_APP_DATASCIENCE_API_URL?: string;
  REACT_APP_ADB_URL?: string;
  REACT_APP_BO_URL?: string;
  REACT_APP_FT_URL?: string;
}

const DEFAULT_CONF = {
  REACT_APP_OKTA_ISSUER_URL: "",
  REACT_APP_OKTA_CLIENT_ID: "",
  REACT_APP_OKTA_SCOPES: "",
  REACT_APP_DATASCIENCE_API_URL: "",
  REACT_APP_ADB_URL: "",
  REACT_APP_BO_URL: "",
  REACT_APP_FT_URL: "",
};

const useConfFetch = () => {
  const [conf, setConf] = useState(DEFAULT_CONF);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchConf = async () => {
    setError(false);

    try {
      const result = await fetch("/conf.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          cache: "no-store",
        },
      }).then((res) => res.json());
      setLoading(false);
      setConf(result);
    } catch (error) {
      setLoading(false);
      setError(true);
      setConf(DEFAULT_CONF);
    }
  };
  useEffect(() => {
    fetchConf();
  }, []);

  return [conf, error, loading];
};

export { useConfFetch };
