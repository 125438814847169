import { Profil } from "../enum/profil.enum";

/**
 * Return actif label depending on value
 * @param {boolean} actif
 * @returns {string}
 */
export function getActifLabel(actif: boolean): string {
  return actif ? "Actif" : "Inactif";
}

/**
 * Return the font colors depending on profil
 * @param {Profil} profil
 * @returns {string}
 */
export function getColorByProfil(profil?: Profil): string {
  switch (profil) {
    case Profil.BUYER:
    case Profil.SELLER:
      return "#003f7d";
    default:
      return "primary.main";
  }
}
