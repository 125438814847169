/**
 * Retrieve a param url from key
 * @param {string} key - Param key we want to retrieve
 * @returns {string | null}
 */
export function getUrlParameter(key: string): string | null {
  const searchParams = new URLSearchParams(
    new URL(window.location.href).search.replace("+", "%2B")
  );
  const paramValue = searchParams.get(key);
  return paramValue ? encodeURI(paramValue) : null;
}

/**
 * Get phone from parameter and normalize
 * @param {string} key - Param key we want to retrieve 
 * @returns {string | null}
 */
export function getPhoneParameter(key: string): string | null {
      const phone = getUrlParameter(key);
      if (phone) {
        // Add '+' symbol at start of phone if doesn't exist to be normalized format
        let newPHone: string = phone;
        if (phone?.charAt(0) !== '+') {
          newPHone = `+${phone}`
        }
        return newPHone;
      }
      return null;
}