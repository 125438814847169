import { Profil } from "../enum/profil.enum";
import { UnitType, unitTypeLabel } from "../enum/unit.enum";
import { Conf } from "../hooks/useConfFetch";
import {
  CustomerTicket,
  Entity,
  FicheClientApiData,
  MappedAcount,
  MappedFicheClient,
  Mission,
  UnitsList,
} from "../interfaces/ficheClient.interface";
import { getActifLabel } from "../utils/displayUtils";
import { getAccountLink, getFicheLink } from "../utils/urlMappingUtils";

export abstract class PropertyStakeholder {
  protected urls: {
    adb: string | undefined;
    bo: string | undefined;
    ft: string | undefined;
  } = {
    adb: "",
    bo: "",
    ft: "",
  };

  private static instance: Map<string, unknown> = new Map();

  constructor(conf: Conf) {
    this.setUrl(conf);
    // Prevent direct instantiation
    if (new.target === PropertyStakeholder) {
      throw new Error("Cannot instantiate parent directly.");
    }
  }

  static getInstance<T>(this: new (...args: any[]) => T, ...args: unknown[]): T {
    const className = this.name;
    if (!PropertyStakeholder.instance.has(className)) {
      PropertyStakeholder.instance.set(className, new this(...args));
    }
    return PropertyStakeholder.instance.get(className) as T;
  }

  /**
   * Return the number of tickets depending on profil
   * @param {UnitsList} unit
   * @param {CustomerTicket[]} customerTicketList
   * @param {string} entityId
   * @returns
   */
  protected abstract getTicketsCount(unit: UnitsList, customerTicketList: CustomerTicket[], entityId: string): number;

  /**
   * Return the number of missions depending on profil
   * @param {Profil} profil
   * @param {string} entityId
   * @param {Mission[]} missionList
   * @param {UnitsList} unit
   * @returns
   */
  protected abstract getMissionsCount(entityId: string, missionList: Mission[], unit: UnitsList): number;

  /**
   * Build accounts levels
   * @param {FicheClientApiData} data - Raw data
   * @param {Entity} entity - Linked Entity to Account
   * @returns {Map<string, MappedAcount[]>}
   */
  protected abstract buildAccounts(data: FicheClientApiData, entity: Entity): Map<string, MappedAcount[]>;

  /**
   * Get account id
   * @param {UnitsList} unit
   * @returns {string}
   */
  protected abstract getAccountId(unit: UnitsList): string;

  /**
   * Get complete Address
   * @param {UnitsList} unit
   * @returns {string}
   */
  protected abstract getCompleteAddress(unit: UnitsList): string;

  private setUrl(conf: Conf): void {
    this.urls = {
      adb: conf.REACT_APP_ADB_URL,
      bo: conf.REACT_APP_BO_URL,
      ft: conf.REACT_APP_FT_URL,
    };
  }

  /**
   * Format data to structured data
   * @param {FicheClientApiData} rawData - Raw data from api
   * @returns {MappedFicheClient[]}
   */
  public mapFicheClient(data: FicheClientApiData): MappedFicheClient[] {
    const mappedAccounts: MappedFicheClient[] = [];
    for (const entity of data.entities_list) {
      const mappedAccount: MappedFicheClient = {
        informations: {
          uuid: entity.usage_id,
          civility: entity.civility,
          name: entity.last_name,
          firstname: entity.first_name,
          lastname: entity.last_name,
          birthdate: entity.birth_date,
          email: entity.email,
          phoneNumbers: [...Object.values(entity.phone)].filter((number) => number !== undefined),
          myFonciaStatut: entity.myfoncia_details?.myfonciaIsActive,
          adbUrl: this.getFicheAdbLink(entity),
          boUrl: this.getFicheBoLink(entity),
        },
        accounts: this.buildAccounts(data, entity),
      };

      mappedAccounts.push(mappedAccount);
    }

    return mappedAccounts;
  }

  /**
   * Build the unit and store them by accountId to create a account level
   * @param {FicheClientApiData} data
   * @param {(UnitsList)[]} unitsList
   * @param {Entity} entity
   * @returns {Map<string, MappedAcount[]>}
   */
  protected mapUnits(
    data: FicheClientApiData,
    unitsList: UnitsList[],
    entity: Entity,
    profil: Profil
  ): Map<string, MappedAcount[]> {
    return unitsList.reduce((accumulator: Map<string, MappedAcount[]>, unit: UnitsList) => {
      if (unit.entity_id === entity.entity_id) {
        const accountId = this.getAccountId(unit);
        const item = accumulator.get(accountId);
        // If item exist, It means that an account with the same account_id exist. We store them in the same Key so we push the existing array
        // No need to create a new account because they share the same data
        // We only push new "bien" unit level
        if (item) {
          item[0].properties.push({
            id: unit.unit_id,
            type: this.translateUnitType(unit.unit_type),
            url: this.getUnitAdbLink(unit),
          });
        } else {
          // New key is created and account is built
          const mappedAccount = this.buildAccount(data, entity, unit, profil);
          accumulator.set(accountId, [mappedAccount]);
        }
      }
      return accumulator;
    }, new Map());
  }

  /**
   * Build the account level
   * @param {FicheClientApiData} data
   * @param {Entity} entity
   * @param {UnitsList} unit
   * @param {Profil} profil
   * @returns {MappedAcount}
   */
  protected buildAccount(data: FicheClientApiData, entity: Entity, unit: UnitsList, profil: Profil): MappedAcount {
    const entityId = entity.entity_id;
    const ticketsNumber = this.getTicketsCount(unit, data.customer_ticket_list, entityId);
    const missionsNumber = this.getMissionsCount(entityId, data.mission_list, unit);

    let account: MappedAcount = {
      address: this.getCompleteAddress(unit),
      profil,
      uuid: unit.unit_id,
      statut: getActifLabel(unit.is_mandate_active ?? false),
      ticketsNumber,
      missionsNumber,
      adbUrl: this.getAccountAdbLink(entity, profil),
      boUrl: this.getAccountBoLink(entity, profil),
      paymentType: undefined,
      reminderLevel: undefined,
      lastAGDate: undefined,
      nextAGDate: undefined,
      properties: [],
    };

    return {
      ...account,
      ...this.addExtraAccountData(entity, unit),
    };
  }

  /**
   * Add extra value for account
   * @param {Entity} entity
   * @param {UnitsList} unit
   * @returns {Oobject}
   */
  protected addExtraAccountData(entity: Entity, unit: UnitsList): Partial<MappedAcount> {
    return {};
  }

  /**
   * Build the account link for adb
   * @param {Entity} entity
   * @param {Profil} profil
   * @returns
   */
  protected getAccountAdbLink(entity: Entity, profil: Profil): string | undefined {
    return getAccountLink(this.urls.adb as string, entity.entity_id, profil);
  }

  /**
   * Build the fiche link for adb
   * @param {UnitsLiEntityst} entity
   * @returns
   */
  protected getFicheAdbLink(entity: Entity): string | undefined {
    return getFicheLink(this.urls.adb as string, entity.entity_id);
  }

  /**
   * Build the account link for bo
   * @param {Entity} entity
   * @param {Profil} profil
   * @returns
   */
  protected getAccountBoLink(entity: Entity, profil: Profil): string | undefined {
    return getAccountLink(this.urls.bo as string, entity.entity_id, profil);
  }

  /**
   * Build the fiche link for bo
   * @param {UnitsLiEntityst} entity
   * @returns
   */
  protected getFicheBoLink(entity: Entity): string | undefined {
    return getFicheLink(this.urls.bo as string, entity.entity_id);
  }

  /**
   * Build the unit link for adb
   * @param {UnitsList} unit
   * @returns
   */
  protected getUnitAdbLink(unit: UnitsList): string {
    return `${this.urls.adb}/unit/${unit.unit_id.replace("ML-UNIT-", "")}`;
  }

  /**
   * Return the translated label of unit type
   * @param {unitType} unitType
   * @returns {string}
   */
  protected translateUnitType(unitType: UnitType): string {
    return unitTypeLabel[unitType] ?? unitType;
  }

  /**
   * Flatten array map of units
   * @param {units: Map<string, MappedAcount[]>[]} units
   * @returns
   */
  protected flattenUnits(units: Map<string, MappedAcount[]>[]) {
    const flattenUnits = units.flatMap((unit) => new Map(unit));
    return new Map<string, MappedAcount[]>(flattenUnits.flatMap((unit) => [...unit]));
  }
}
